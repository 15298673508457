import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Interviews: your why, your story",
  "author": "Purepost",
  "date": "2018-02-09T06:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "interviews-your-why-your-story",
  "draft": false,
  "meta_title": "Interviews: your why, your story"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Once you have a solid understanding of the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "http://blog.purepost.co/2015/11/interviews-the-breakdown/"
        }}>{`breakdown`}</a></strong>{` and `}<strong parentName="p"><a parentName="strong" {...{
          "href": "http://blog.purepost.co/interviews-best-practices/"
        }}>{`best practices`}</a></strong>{` in the interviewing process, you can begin to drill down to the more specific and detailed practices which can push you over the top. These vary from developing your story to mastering your non-verbals, and ultimately to honing your impromptu responses to standard – and surprise – questions.  `}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://alumni.darden.edu/s/1535/09-darden/oneCol.aspx?sid=1535&gid=9&pgid=6473"
      }}>{`Darden Alumni Career Services`}</a>{` website has a very nicely organized set of tips and best practices that are highly effective and can be applied in just about any interviewing scenario. Having your own personal story down to a science is paramount to setting the tone for the interview.`}</p>
    <p>{`There will likely be some form of a question where the interviewer asks you to tell her a little bit about yourself. When articulating your story make certain to incorporate how your experience will make you a valuable addition to the company while being as concise and compelling as possible. It's really a sort of "elevator pitch" about yourself. Think of it this way, you have one elevator ride of two minutes or less to tell a person why they should hire you. Your story, as with the rest of the interview practice, makes all the difference; rehearse your story over and over again until you’ve mastered the flow and timing to perfection.`}</p>
    <p><a parentName="p" {...{
        "href": "http://psychology.about.com/od/nonverbalcommunication/a/nonverbaltypes.htm"
      }}>{`Nonverbal communication`}</a>{` is another factor that can dramatically impact your success in an interview. Having good posture and positive eye contact is crucial, along with avoiding crossing your arms and fidgeting with your feet. A good general practice is to mirror the nonverbal behaviors of your interviewer and to actively listen while following their pace and flow. By focusing on enjoying the interaction you can help to foster a positive engaging atmosphere in the interview space. In crafting your responses to questions, it’s typically best not to memorize your answers, instead, it’s better to have a general idea of how you would reply to a particular question and then allow yourself to respond extemporaneously to create a more natural conversational answer. This focus on impromptu answers assists you in preparing for unexpected questions and allows you to be more adaptive and flexible. The `}<a parentName="p" {...{
        "href": "https://alumni.darden.edu/s/1535/09-darden/oneCol.aspx?sid=1535&gid=9&pgid=6473"
      }}>{`Darden School Alumni Services`}</a>{` recommends, “If you happen to become nervous or blank on a specific question, breathe deeply.  Another trick is to ‘ground’ yourself by noticing the feeling of your feet on the floor, or your hands on your lap.  If you need a few moments to think while you formulate your answer, take them.  It’s okay to break eye contact; in fact, most people look away while they are thinking.”`}</p>
    <p>{`By far one of the most important aspects of the interview is the closing.  As humans, we are hardwired to remember the last portion of an experience, as Daniel Kahneman mentions in his `}<a parentName="p" {...{
        "href": "https://www.ted.com/talks/daniel_kahneman_the_riddle_of_experience_vs_memory"
      }}>{`TEDx video “The Riddle of Experience”`}</a>{`. Even if the entire interview went perfectly up to that point, an awkward closing or failure to ask poignant questions at the end of the interview risks you leaving the interviewer with a bad memory of the experience. Make certain to practice a smooth natural finish to the interview, where you allow the interviewer to take the lead and express your gratitude for the opportunity to be considered for the position.`}</p>
    <p>{`(Sidenote: If discovering your "why" and telling your story are new concepts to you, there are great leaders and research out there to guide and inspire. I highly recommend reading, "Start with Why" by Simon Sinek and we'll soon have a blog feature detailing additional avenues and resources in storytelling.)`}</p>
    <p>{`~The Purepost Team`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      